import React, { useContext } from "react";
import { LanguageContext } from "../../context";
import LessonItem from "./lesson-item";
import Utils from "../../utils";
import { navigate } from "gatsby";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import PropTypes from "prop-types";
import NoSearchResults from "../../utils/no-search-results";
import { Link } from "gatsby";

const LessonList = (props) => {
  const {
    title,
    schoolPosts,
    lang,
    className,
    classContainer,
    classRow,
    buttonUrl,
    buttonTitle,
    includeTags,
  } = props;

 
  // Ensure that the schoolPosts is an array before passing it to the grouping function
  const groupedSchoolPosts = getGroupedPosts(schoolPosts) || {};



  const { t } = useContext(LanguageContext);
  const breakpoints = useBreakpoint();

  // Default image URL (from the internet)
  const defaultImageUrl = "https://via.placeholder.com/294x192.png?text=No+Image";

  return (
    <section className={className}>
      <div className={classContainer}>
        {title && (
          <div className="row m-b-56">
            <div className="col-md-12">
              <h2 className="h2-medium-c bordeux H2-Tablet-Medium-C--bordeaux">{title}</h2>
            </div>
          </div>
        )}
        <div className="row m-b-64 gutter-0">
          {Object.entries(groupedSchoolPosts).map(([key, lessons], index) => {
            return (
              <div key={key}>
                <div>
                  <h3 className="H4-Medium-C---bordeaux">{key}</h3>
                </div>
                <div className="row">
                  {lessons?.map((node, index) => {
                    const imageUrl =
                      node?.relationships?.field_image?.image_style_uri?._294x192 || defaultImageUrl;

                    return (
                      <LessonItem
                        key={node?.path?.alias || index} // Fallback key if path.alias is not available
                        title={node?.title}
                        description={node?.body?.summary}
                        date={node?.created}
                        path={node?.path?.alias}
                        image={imageUrl}
                        className={`${breakpoints.sm_md ? "col-md-4" : classRow} ${"item-" + index} blog-item m-b-40`}
                        includeTags={includeTags}
                        categories={node?.relationships?.field_serie}
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}

        {(Object.keys(groupedSchoolPosts).length === 0) && <NoSearchResults />}
        </div>
        {buttonUrl && (
          <div className="row p-l-r-5 text-center">
            <div className="container">
              <Link
                to={Utils.getPath(buttonUrl, lang)}
                className="section-cta btn btn-outline btn-lg btn-outline-bordeaux"
              >
                {t(buttonTitle)}
              </Link>
              <button
                className="section-cta btn btn-outline btn-lg btn-outline-bordeaux hide"
                onClick={() => navigate(Utils.getPath(buttonUrl, lang))}
              >
                {t(buttonTitle)}
              </button>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export const getGroupedPosts = (data) => {
  // Check if the data has a 'nodes' property or is an array directly
  const lessonsArray = data?.nodes || (Array.isArray(data) ? data : []);

  const groupedData = lessonsArray.reduce((acc, curr) => {
    const serieName = curr?.relationships?.field_serie?.name || 'Uncategorized';
    acc[serieName] = acc[serieName] || [];
    acc[serieName].push(curr);
    return acc;
  }, {});

  return groupedData; // Return the grouped data, which will be an empty object if no valid data exists
};


LessonList.propTypes = {
  className: PropTypes.string,
  classContainer: PropTypes.string,
  classRow: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonUrl: PropTypes.string,
  includeTags: PropTypes.bool,
};

LessonList.defaultProps = {
  className: `row- blog-list m-b-92 p-b-80- p-l-r-189`,
  classContainer: `blog-list-content`,
  classRow: "col-md-4 m-r-90 article-list-item max-width-294",
  buttonTitle: "",
  buttonUrl: "",
  includeTags: false,
};

export default LessonList;
