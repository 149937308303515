import * as React from 'react'
import { Link } from 'gatsby'
import Icon from "../icon"

// Create URL path for numeric pagination
const getPageNumberPath = (currentIndex, alias) => {
  if (currentIndex === 0) {
    return `${alias}/1`
  }
  return `${alias}/${(currentIndex + 1)}`
}

 const Pagination = ({ pageInfo, alias }) => {
  if (!pageInfo) return null
  const { currentPage, pageCount } = pageInfo
  if (!pageCount || !currentPage) return null

  // Create URL path for previous and next buttons
  const prevPagePath = currentPage - 1 === 1 ? `${alias}/1` : `${alias}/` + (currentPage - 1).toString()
  const nextPagePath = `${alias}/` + (currentPage + 1).toString()

  // Check if page is first or last to disable previous and next buttons
  const prevClass = currentPage === 1 ? 'disabled' : 'enabled'
  const nextClass = currentPage === pageCount ? 'disabled' : 'enabled'

  return (
    <nav className="nav-pagination" aria-label="Page navigation">
     <ul className="pagination justify-content-center">
     <li className={[prevClass, 'page-item'].join(' ')}>
      <Link
        className={[prevClass, 'page-link'].join(' ')}
        to={prevPagePath}
        rel="prev"
        aria-label="Previous"
        >

        <span aria-hidden="true" className="btn-nav-wrapper">
        <Icon
          icon="icons-32-x-32-export-chevron-down-2"
          color="bordeaux"
          className="previous"
          size={32}
        />
        </span>
      </Link>
      </li>
      {/*  Render numeric pagination  */}
      {Array.from({ length: pageCount }, (_, i) => {
        let numClass = 'pageNumber'
        if (currentPage === i + 1) {
          numClass = 'currentPage'
        }
        return (
          <li className={[numClass, 'page-item'].join(' ')} key={i}>
            <Link
              to={getPageNumberPath(i, alias)}
              className={[numClass, 'page-link'].join(' ')}
              key={i + 1}
              >
              {i + 1}
            </Link>
          </li>
        )
      })}
      <li className={[nextClass, 'page-item'].join(' ')}>
        <Link
          className={[nextClass, 'page-link'].join(' ')}
          to={nextPagePath}
          rel="next"
          aria-label="Next"
          >
          <span aria-hidden="true" className="btn-nav-wrapper">
            <Icon
              icon="icons-32-x-32-export-chevron-down-2"
              color="bordeaux"
              className="next"
              size={32}
            />
          </span>
        </Link>
      </li>
      </ul>
    </nav>
  )
}

export default Pagination
