import React, { useContext, useState } from "react";
import { graphql, navigate } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Header from "../components/header";
import Footer from "../components/footer";
import LessonList from "../components/school/lesson-list";
import Pagination from "../components/pagination";
import Banner from "../components/banner";
import { AuthContext } from "../context/AuthContext";
import { LanguageContext } from "../context";
import Button2 from "../components/button-generic";

const PrivateSchoolPage = ({ data, location, pageContext }) => {
  const post = data?.nodePage;
  const privateLessons = data?.privateLessons?.nodes;
  const isBrowser = typeof window !== "undefined"; // Check if running in the browser

  // Access context values unconditionally
  const authContext = useContext(AuthContext);
  const languageContext = useContext(LanguageContext);

  // Safely access context values based on browser check
  const { t } = isBrowser ? languageContext : { t: (text) => text };
  const { user, loading } = isBrowser && authContext ? authContext : { user: null, loading: false };

  const translationPaths = {
    en: `/en${post?.path?.alias}`,
    es: `/es${post?.path?.alias}`,
  };

  const [currentPage, setCurrentPage] = useState(pageContext?.currentPage || 1);

  // If loading, show a loader
  if (loading) {
    return <div>{t("Loading...")}</div>;
  }

  // If user is not authenticated, show the login prompt
  if (!user) {
    return (
      <Layout location={location} langcode={post?.langcode} translationPaths={translationPaths}>
        <Seo title={post?.title} description={post?.body?.summary} />
        <Header lang={post?.langcode} location={location} />
        <div className="container private-page-container">
          <div className="private-page-content">
            <h2>{t("This is a private page. Please log in to access the content.")}</h2>
            <Button2
              className="btn btn-outline btn-outline-visit-us my-2 my-sm-0"
              title={t("Log In")}
              onClick={() => navigate("/login")}
            />
          </div>
        </div>
        <Footer lang={post?.langcode} />
      </Layout>
    );
  }

  // Once authenticated, show the content
  return (
    <Layout location={location} langcode={post?.langcode} translationPaths={translationPaths}>
      <Seo title={post?.title} description={post?.body?.summary} />
      <Header lang={post?.langcode} location={location} />

      <Banner
        post={post}
        height="408px"
        backgroundColor="green"
      />

      <div className="container">
        <LessonList
          schoolPosts={privateLessons || []}
          lang={post?.langcode}
          className="lesson-list m-b-108 p-t-80"
          classContainer="container p-0"
          classRow="col-12 col-md-3"
          includeTags={true}
        />

        {pageContext?.pageInfo?.pageCount > 1 && (
          <Pagination
            pageInfo={pageContext?.pageInfo}
            alias={post?.path?.alias}
            currentPage={currentPage}
            onPageChange={(newPage) => setCurrentPage(newPage)}
          />
        )}
      </div>

      <Footer lang={post?.langcode} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query PrivateSchoolPage($drupal_internal__nid: Int, $langcode: String!, $limit: Int!, $skip: Int!) {
    nodePage(drupal_internal__nid: { eq: $drupal_internal__nid }, langcode: { eq: $langcode }) {
      title
      langcode
      body {
        processed
        summary
        value
      }
      path {
        alias
      }
      relationships {
        field_image {
          image_style_uri {
            large
            medium
            thumbnail
            wide
            _294x192
            _541x359
            _653_432
          }
        }
      }
    }
    privateLessons: allNodeLesson(
        filter: { langcode: { eq: $langcode }, field_access_control: {eq: "Private"} }
        limit: $limit
        skip: $skip
        sort: {fields: created, order: DESC}
      )  {
        nodes {
          langcode
          title
          created(formatString: "DD MMMM YYYY", locale: $langcode)
          body {
            summary
            value
          }
          path {
            alias
          }
          field_notes_and_questions {
            description
          }
          relationships {
            field_serie {
              name
            }
            field_image {
              image_style_uri {
                _653_432
                _120x120
                _294x192
              }
            }
            field_notes_and_questions {
              uri {
                url
              }
            }
          }
          path {
            alias
          }
          field_url_youtube
          body {
            summary
            processed
            value
          }
        }
        pageInfo {
          currentPage
          hasNextPage
          hasPreviousPage
          itemCount
          pageCount
          perPage
          totalCount
        }
      }
  }
`;

export default PrivateSchoolPage;
